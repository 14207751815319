import whitepaper from "../pages/Resources/whitepapers";

export const metaData = {
    common:{
        title: 'Aspire Tech Services & Solutions Corporation',
        canonical: 'https://www.aspiretss.com/',
        meta: {
            desc: 'Aspire Tech provides superior IT services and solutions to streamline and enhance your business operations.',
            keyword: 'IT Services and Solutions Provider',
        },
        banner: {
            heading: 'AspireTSS',
            tagLine: '',
        },
    },
    training:{
            title: 'Comprehensive Cyber Security Training Programs | AspireTSS',
            canonical: 'https://www.aspiretss.com/resources/training',
            meta: {
                desc: 'Enhance your skills with our comprehensive cyber security training programs. Learn from industry experts.',
                keyword: 'Cyber Security Training Programs',
            },
            banner: {
                heading: 'Training',
                tagLine: '',
            },
        },
    blog:{
            title: 'Stay Updated with Top Cyber Security Blogs | AspireTSS',
            canonical: 'https://www.aspiretss.com/resources/blog',
            meta: {
                desc: 'Read our top cyber security blogs to stay updated on industry trends, tips, and best practices.',
                keyword: 'Top Cyber Security Blogs',
            },
            banner: {
                heading: 'Blog',
                tagLine: '',
            },
        },
    whitepaper:{
        title: 'Explore Expert Cyber Security Whitepapers | AspireTSS',
        canonical: 'https://www.aspiretss.com/resources/whitepaper',
        meta: {
            desc: 'Access detailed cyber security whitepapers to stay ahead of threats and enhance your knowledge.',
            keyword: 'Expert Cyber Security Whitepapers',
        },
        banner: {
            heading: 'White Paper',
            tagLine: '',
        },
        },
    reports:{
            title: 'Aspire Tech Reports',
            meta: {
                title: 'Reports',
                desc: ``,
            },
            banner: {
                heading: 'Reports',
                tagLine: '',
            },
        },
    events:{
        title: 'Join Leading Cyber Security Events & Workshops | AspireTSS',
        canonical: 'https://www.aspiretss.com/resources/events',
        meta: {
            desc: 'Join our cyber security events and workshops to stay ahead in the field. Register today!',
            keyword: 'Leading Cyber Security Events & Workshops',
        },
        banner: {
            heading: 'Events',
            tagLine: '',
        },
    },
    wabiners:{
        title: 'Interactive Cyber Security Webinars & Training | AspireTSS',
        canonical: 'https://www.aspiretss.com/resources/webinars',
        meta: {
            desc: 'Attend our expert-led cyber security webinars to enhance your skills and stay informed.',
            keyword: 'Cyber Security Webinars',
        },
        banner: {
            heading: 'Wabiners',
            tagLine: '',
        },
    },
    aboutaspire:{
        title: 'About Aspire Tech | Leading IT Solutions & Cybersecurity Services',
        canonical: 'https://www.aspiretss.com/company/about',
        meta: {
            desc: 'Learn about Aspire Tech, a leader in IT solutions and cybersecurity services. Discover our mission, values, and commitment to innovation and client success',
            keyword: 'Leading IT Solutions & Cybersecurity Services',
        },
        banner: {
            heading: '',
            tagLine: '',
        },
    },
    careers:{
        title: 'Join Aspire Tech: Exciting Career Opportunities Await',
        canonical: 'https://www.aspiretss.com/company/Careers',
        meta: {
            desc: 'Explore rewarding career opportunities at Aspire Tech. Join us to advance your career in a dynamic and innovative environment.',
            keyword: 'Exciting Career Opportunities',
        },
        banner: {
            heading: '',
            tagLine: '',
        },
    },
    contact:{
        title: 'Contact Aspire Tech',
        canonical: 'https://www.aspiretss.com/company/contact',
        meta: {
            desc: 'Contact our cyber security experts today for top-notch protection and support.',
            keyword: 'Contact Cyber Security',
        },
        banner: {
            heading: '',
            tagLine: '',
        },
    },
    partner:{
        title: 'Partner Program at Aspire Tech | Grow with Us Today',
        canonical: 'https://www.aspiretss.com/partners/partner-program',
        meta: {
            desc: "Discover Aspire Tech's Partner Program and boost your business success.",
            keyword: 'Aspire Tech Partner Program',
        },
        banner: {
            heading: '',
            tagLine: '',
        },
    },
  }