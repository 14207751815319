import React, {useState} from "react";
import { ReactComponent as SendIcon } from "../../assets/icons/send.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo-blue.svg";
import banner from "../../assets/images/footer-banner.webp";
import {
  FaTumblrSquare,
  FaPinterestSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
  FaLinkedin,
  FaTwitterSquare,
  FaFacebookSquare,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import MoveToTop from "./MoveToTop";
import cogoToast from "cogo-toast";
import {useForm} from "react-hook-form";

const Footer = () => {
  const d = new Date();
  const year = d.getFullYear();

    const {
        register,
        handleSubmit,
        reset
    } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [emailErrorMsg, setEmailError] = useState(false);

    const handleChange =(val)=>{
      let emailChk = /@(gmail\.com|hotmail\.com|outlook\.com|aol\.com|proton\.com|protonmail\.com|zohomail\.com|gmx\.com|gmxmail\.com|yahoo\.com|mail2world\.com|icloud\.com|tuta\.com|10minutemail\.com|juno\.com|mailtrap\.com)$/;
      if(val.match(emailChk)){
        setEmailError(true);
      }else{
          setEmailError(false);
      }
      if(val.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/)){
          setError(false);
      }else{
          setError(true);
      }
    }
    // const handleChange =(val)=>{
    //     //console.log(val);
    //     if(val.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
    //         setError(false);
    //     }else{
    //         setError(true);
    //     }
    // }

    const onSubmit = data => {
        //console.log(data);
        setIsLoading(true);
        // fetch('http://localhost:8000/api/news-letter-store', {
        fetch('https://backend.aspiretss.com/api/news-letter-store', {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(response =>response)
        .then(result => {
            // console.log(result);
            setIsLoading(false);
            reset();
            if(result.status === 200){
                cogoToast.success(<p>Your request  successfully summited. Please check your mail for more details.</p>, {
                    position: 'top-right'
                });
            }
        });
    }


  return (
    <footer id="footer">
      <div className="py-5 footer-top px-2 position-relative">
        <div className="container pt-0 pt-lg-4">
          <div className="row">
          <div className="col-4 col-md-2 order-md-1 order-1 d-flex justify-content-start align-items-center">
              <Logo style={{height:"90px", width:"135px"}} className="mb-3" />
            </div>
            <div className="col-12 col-md-6 order-md-2 order-3">
              <div className="">
                <p className="title text-orange font-14 mb-2">NEWSLETTER</p>
                  <h2 className="text-white mb-4">Get Our Updates In Your Email</h2>
                  <form className="info-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-group mb-5">
                      <input
                        type="email"
                        className="form-control py-3 px-4 text-dark bg-transparent"
                        placeholder="Your Email"
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^\S+@\S+$/i,
                                message: "This is not a valid email",
                            },
                        })}
                        onChange={(e)=> handleChange(e.target.value)}
                      />

                      {!emailErrorMsg && !error ? <button type="submit" className="bg-white input-group-text px-3" >
                            <SendIcon />
                          <span style={{marginLeft: "10px",color: "#163560"}}>{isLoading && <span>Sending...</span>}</span>
                          </button> : <span className="bg-white input-group-text px-3" style={{opacity: '.8'}}> <SendIcon /> </span>}

                   
                      {/* <button type="submit" className="bg-white input-group-text px-3" >
                        <SendIcon />
                       <span style={{marginLeft: "10px",color: "#163560"}}>{isLoading && <span>Sending...</span>}</span>
                      </button> */}

                    </div>
                      {error?<p style={{color: "red"}}>Email is invalid yet, please check</p>:''}
                      <p className="text-danger mb-0" style={{ display: !emailErrorMsg ? 'none' : 'block' }}>*Must be valid your company email</p>
                  </form>
              </div>              
             </div>
            <div className="col-4 col-md-4 order-md-3 order-2 ms-auto">
              <figure className="text-center text-lg-start">
                <img src={banner} alt="banner" className="img-fluid" />
              </figure>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4 col-lg-4 col-xl-3 col-6 mb-5">
            <h4 className="text-primary">Products</h4>
            <ul className="list-unstyled">
                <li>
                  <Link className="text-light" to="/products/a-siem">Aspire SIEM</Link>
                </li>
                <li>
                  <Link className="text-light" to="/products/a-grc">Aspire GRC</Link>
                </li>
                <li>
                  <Link className="text-light" to="/products/a-sat">Aspire Awareness Training</Link>
                </li>
                <li>
                  <Link className="text-light" to="/products/a-phish">Aspire Phishing Simulation</Link>
                </li>
                <li>
                  <Link className="text-light" to="/products/a-shc">Aspire Security Health Check</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-lg-4 col-xl-3 col-6 mb-5">
            <h4 className="text-primary">Top Services</h4>
              <ul className="list-unstyled">
                <li>
                  <Link className="text-light" to="/managedservice/SOCaasServices">SOCaas Services</Link>
                </li>
                <li>
                  <Link className="text-light" to="/managedservice/mssp">MSSP Services</Link>
                </li>
                <li>
                  <Link className="text-light" to="/managedservice/managedIt">Managed It</Link>
                </li>
                <li>
                  <Link className="text-light" to="/managedservice/cybersecurity">Cyber Security</Link>
                </li>
                <li>
                  <Link className="text-light" to="/managedservice/cloudsecurity">Cloud Security</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-lg-4 col-xl-3 col-6 mb-5">
            <h4 className="text-primary">Company</h4>
              <ul className="list-unstyled">
                <li>
                  <Link className="text-light" to="/company/about">About</Link>
                </li>
                <li>
                  <Link className="text-light" to="/company/careers">Careers</Link>
                </li>
                <li>
                  <Link className="text-light" to="/termscondition">Terms and Conditions</Link>
                </li>
                {/* <li>
                  <Link className="text-light" to="/partners/partner-program">Partner Program</Link>
                </li> */}
                <li>
                  <Link className="text-light" to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link className="text-light" to="/company/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-xl-2 col-md-3 col-6 px-0 mx-0">
              <ul className="list-unstyled">
                <li>
                  <span className="text-white">Let’s Chat</span>
                </li>
                <li>
                  <a className="" href="mailto:info@aspiretss.com">
                    info@aspiretss.com
                  </a>
                  <div className="d-flex gap-2 lh-1 social-icons flex-wrap">
                      <a style={{background:'#3b5998'}} href="https://www.facebook.com/aspiretss" target="_blank" rel="noreferrer">
                        <FaFacebookSquare />
                      </a>
                      <a style={{background:'#0093AB'}} href="https://twitter.com/AspireTechServ3" target="_blank" rel="noreferrer">
                        <FaTwitterSquare />
                      </a>
                      <a style={{background:'#0077b5'}} href="https://www.linkedin.com/company/aspire-tech-services-and-solution-limited" target="_blank" rel="noreferrer">
                        <FaLinkedin />
                      </a>
                      <a style={{background:'#cd201f'}} href="https://www.youtube.com/channel/UCcSWST022dMiNR110htQbdQ" target="_blank" rel="noreferrer">
                        <FaYoutubeSquare />
                      </a>
                      <a style={{background:'#3f729b'}} href="https://www.instagram.com/aspire_tech77" target="_blank" rel="noreferrer">
                        <FaInstagramSquare />
                      </a>
                      <a style={{background:'#bd081c'}} href="https://www.pinterest.com/aspire_tech" target="_blank" rel="noreferrer">
                        <FaPinterestSquare />
                      </a>
                      <a style={{background:'#00405d'}} href="https://www.tumgir.com/aspiretss" target="_blank" rel="noreferrer">
                        <FaTumblrSquare />
                      </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
            <MoveToTop />
      </div>      
      <div className="footer-bottom py-3 text-center font-14 text-white">
        Copyright@{year}. All right reserved. Aspire Tech Services & Solutions Limited, Bangladesh.
      </div>
    </footer>
  );
};

export default Footer;
