import React from 'react';
import BannerSection from '../../components/Inner/frames/BannerSectionWithoutBtn';
import { CareersData } from '../../data/Company/Careers';
import GetInTouch from '../../components/home/GetInTouch';
import { homeData } from '../../data/homeData';
import Whitepapper from '../../components/Inner/frames/Whitepaper';



const whitepaper = () => {
  return (
    <div>
      <BannerSection banner={CareersData.banner} />{' '}
       <section>
       
         <Whitepapper></Whitepapper>
       </section>
    
      <div className="container-fluid whiteBackground">
        <section id="get-in-touch" className="py-5 px-4 px-xl-5">
          <GetInTouch contactData={homeData.contactInfo} />
        </section>
      </div>
    </div>
    
  );
};

export default whitepaper;