import React from 'react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Dropdown, Nav, Navbar } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/aspire-logo.png';
import DemoModal from '../modal/getDemoModal';
import { SiDatabricks } from 'react-icons/si';
import SolutionNavItem from './SolutionNavItem';
import ManagedNavItem from './ManagedNavItem';
import { singleNavDropdown } from '../../data/mainNavData';
import { NavHashLink } from 'react-router-hash-link';

const MainNavBar = () => {
  const { products, partners, resources, aboutUS } = singleNavDropdown;
  const [showDropdownProducts, setShowDropdownProducts] = useState(false); 
  const [showDropdownResources, setShowDropdownResources] = useState(false); 
  const [showDropdownPartners, setShowDropdownPartners] = useState(false); 
  const [showDropdownCompany, setShowDropdownCompany] = useState(false); 
  const [showDropdownSolutions, setShowDropdownSolutions] = useState(false); 
  const [showDropdownManaged, setShowDropdownManaged] = useState(false); 
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);
  const [expanded, setExpanded] = useState(false);

  //for menu outside s//
  let menuRef = useRef();
  //for menu outside e//

  let location = useLocation();
  const homePagePath = location.pathname.split('/')[1] === '';
  const [isNavBlue, setIsNavBlue] = useState(false);

  const removeAll = ()=>{
    setShowDropdownProducts(false);
    setShowDropdownResources(false);
    setShowDropdownPartners(false);
    setShowDropdownCompany(false);
    setShowDropdownSolutions(false);
    setShowDropdownManaged(false);
  }

  useEffect(() => {
    
    //for outside click menu hide s//
    let handler = (e)=>{
      if(!menuRef.current.contains(e.target)){
        setShowDropdownProducts(false);
        setShowDropdownResources(false);
        setShowDropdownPartners(false);
        setShowDropdownCompany(false);
        setShowDropdownSolutions(false);
        setShowDropdownManaged(false);
        setShowDropdownManaged(false);
        setMobileNav(false);
        setIsNavBlue(false);
        setExpanded(false);
      } 
      if(mobileNav) {
        setMobileNav(false);
        setIsNavBlue(false);
      }
    };
    document.addEventListener("click", handler);
    //for outside click menu hide e//
    //before ok code s//
    let listener = null;
    listener = document.addEventListener('scroll', (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      scrolled >= 120 ? setIsNavBlue(true) : setIsNavBlue(false);
    });
    return () => {
      document.removeEventListener('scroll', listener);
    };
   //before ok code e//  
  }, [isNavBlue]);
  

  return (
    <>
      <header
        id="navbar"
        className={`${mobileNav && 'mobile-nav'} ${
          !homePagePath | isNavBlue && 'blue-nav'
        }`}
      >
        <Navbar expanded={expanded} expand="lg" className="px-4 px-md-5" ref={menuRef}>
          <Nav.Link href="/" className="p-0">
            <figure className="navbar__logo m-0">
              <img src={logo} alt="logo" />
            </figure>
          </Nav.Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav"
            className={`${mobileNav ? 'toggler-cross' : 'toggler-main'} btn`}
            onClick={() => {
              setMobileNav(!mobileNav);
              setExpanded(expanded ? false : 'expanded');
            }}
            style={{background:'#f47960'}}
          />
          <Navbar.Collapse id="basic-navbar-nav" className="mt-4 mt-lg-0">
            <Nav className="ms-auto mb-4 mb-lg-0">
              <Dropdown
                show = {showDropdownProducts}
                onClick={() => {
                  setIsNavBlue(true);
                  removeAll();
                  setShowDropdownProducts(!showDropdownProducts);
                }}
                className="products me-3"
              >
                <span>
                  <Dropdown.Toggle
                    variant="transparent"
                    className="dropdown-title"
                  >
                    Products
                  </Dropdown.Toggle>
                </span>
                <Dropdown.Menu id="proMenu" className="p-0 single-dropdown innerDropDown" style={{maxWidth:"680px"}}>
                  <h4 className="text-white text-center pt-3">
                    <SiDatabricks className="mb-1 text-white" /> Products
                  </h4>
                  <ul className="product-options pt-0 font-16">
                    {products.map((item, index) => (
                      <li key={index}>
                        <Link to={`${item.link}`}>
                          <div className='menuItem'
                           onClick={() => {
                            setExpanded(expanded ? false : 'expanded');
                          }}
                          >
                              <div className='imageItem'>
                                  <img src={item.navImage} alt={item.navImage} />
                                  <span className='shortName'>{item.navItem}</span> 
                              </div>
                              <div className='titleItem'>
                                <span>{item.navItemFull}</span>
                              </div>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
              <SolutionNavItem setIsNavBlue={setIsNavBlue} expanded={expanded} show={showDropdownSolutions} statefunc={setShowDropdownSolutions} clear={removeAll} />
              <ManagedNavItem navBlue={() => setIsNavBlue(true)} expanded={expanded}  show={showDropdownManaged} statefunc={setShowDropdownManaged} clear={removeAll} />
              <Dropdown
                show = {showDropdownResources}
                onClick={() => {    
                  removeAll();  
                  setShowDropdownResources(!showDropdownResources);
                  setIsNavBlue(true);                  
                }}
              >
                <span>
                  <Dropdown.Toggle
                    variant="transparent"
                    className="dropdown-title me-3"
                  >
                    Resources
                  </Dropdown.Toggle>
                </span>
                <Dropdown.Menu className="p-0 single-dropdown innerDropDown"  style={{maxWidth:"510px"}}>
                  <h4 className="text-white text-center pt-3">
                    <SiDatabricks className="mb-1 text-white" /> Resources
                  </h4>
                  <ul className="resource-options pt-0 font-16">
                    {resources.map((item, index) => (
                        <li key={index}>
                          {/* <Link to={`${item.link}`}> */}
                          <NavHashLink to={`${item.link}`}
                          className='menuItem'
                          onClick={() => {
                            setExpanded(expanded ? false : 'expanded');
                          }}
                          >
                            <div>
                                <div className='imageItem'>
                                    <img src={item.navImage} alt={item.navImage} />
                                </div>
                                <div className='titleItem'>
                                  <span>{item.navItemFull}</span>
                                </div>
                            </div>
                            </NavHashLink>
                          {/* </Link> */}
                        </li>
                      ))}
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown
                show = {showDropdownPartners}
                onClick={() => { 
                    removeAll();     
                  setShowDropdownPartners(!showDropdownPartners);
                  setIsNavBlue(true);                  
                }}
              >
                <span>
                  <Dropdown.Toggle
                    variant="transparent"
                    className="dropdown-title me-3"
                  >
                    Partners
                  </Dropdown.Toggle>
                </span>
                <Dropdown.Menu className="p-0 single-dropdown innerDropDown">
                  <h4 className="text-white text-center pt-3">
                    <SiDatabricks className="mb-1 text-white" /> Partners
                  </h4>
                  <ul className="options pt-0 font-16">
                    <div>
                      {partners.map((item, index) => (
                        <li key={index}>
                          <Link to={`${item.link}`}
                            className='menuItem'
                            onClick={() => {
                            setExpanded(expanded ? false : 'expanded');
                          }}
                          >{item.navItem}</Link>
                        </li>
                      ))}
                    </div>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown 
                show = {showDropdownCompany}
                onClick={() => {      
                  removeAll();
                  setShowDropdownCompany(!showDropdownCompany);
                  setIsNavBlue(true);                  
                }}
              >
                <span>
                  <Dropdown.Toggle
                    variant="transparent"
                    className="dropdown-title me-3"
                  >
                    Company
                  </Dropdown.Toggle>
                </span>
                <Dropdown.Menu className="p-0 single-dropdown innerDropDown">
                  <h4 className="text-white text-center pt-3">
                    <SiDatabricks className="mb-1 text-white" /> Company
                  </h4>
                  <ul className="options pt-0 font-16">
                    <div>
                      {aboutUS.map((item, index) => (
                        <li key={index}>
                          <Link to={`${item.link}`}
                          className='menuItem'
                          onClick={() => {
                           setExpanded(expanded ? false : 'expanded');
                          }}
                          >{item.navItem}</Link>
                        </li>
                      ))}
                    </div>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>            
          </Navbar.Collapse>
          <div className="ms-2 ms-xl-4 btn-container">
              <button
                onClick={() => {
                  setShowDemoModal(true);
                  setExpanded(false);
                  setMobileNav(false);
                }}
                className="btn-orange font-14 request-btn"
              >
                Request for a Demo
              </button>
          </div>
        </Navbar>
        {showDemoModal && (
          <DemoModal
            show={showDemoModal}
            handleClose={() => setShowDemoModal(false)}
            section='navbar'
            topic='request for demo'
          />
        )}
      </header>
      {!homePagePath && <div className="h-5"></div>}
    </>
  );
};

export default MainNavBar;
