import {Modal} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import cogoToast from "cogo-toast";
import {useState, useEffect} from "react";
import {color} from "tailwindcss/lib/util/dataTypes";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {BsCheck2All} from "react-icons/bs";
import ApiURL from "../../../src/api/ApiURL";
import "../../../src/assets/css/globalCustom.css";


const DemoModal = ({show, handleClose, section="default", topic="default"}) => {


    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm();

    //console.log(handleSubmit);

    // const [value, setValue] = useState()
    const [error, setError]=useState();
    const [isLoading, setIsLoading] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [nameErrorMsg, setNameErrorMsg]=useState(false);
    const [emailErrorMsg, setEmailErrorMsg]=useState(false);
    const [designationErrorMsg, setDesignationErrorMsg]=useState(false);
    const [productes, setProduct]=useState([]);
    //console.log(productes);

    useEffect(() => {
        //fetch("https://backend.aspiretss.com/api/get-product-api")
        fetch(ApiURL.GetProduct)
           .then((res) => {
               return res.json();
           })
           .then((result) => {
               setProduct(result);
           });
   }, []);

   const handleNameChange =(val)=>{
        if(val.match(/^([a-zA-Z\s*$/.]{2,})$/)){      //For empty space  \s*$/
            setNameErrorMsg(false);
        }else{
            setNameErrorMsg(true);
        }
    }
   const designationNameChange =(val)=>{
        if(val.match(/^([a-zA-Z\s*$/.-]{2,})$/)){      //For empty space  \s*$/
            setDesignationErrorMsg(false);
        }else{
            setDesignationErrorMsg(true);
        }
    }

    const handleChange =(val)=>{
        let emailChk = /@(gmail\.com|hotmail\.com|outlook\.com|aol\.com|proton\.com|protonmail\.com|zohomail\.com|gmx\.com|gmxmail\.com|yahoo\.com|mail2world\.com|icloud\.com|tuta\.com|10minutemail\.com|juno\.com|mailtrap\.com)$/;
        if(val.match(emailChk)){
            setEmailErrorMsg(true);
        }else{
            setEmailErrorMsg(false);
        }
        if(val.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/)){
            setError(false);
        }else{
            setError(true);
        }
    }

    const onSubmit = data => {
        setIsLoading(true);
        setSubmitSuccess(false);
        // fetch('https://backend.aspiretss.com/api/request-for-demo-save', {
            fetch(ApiURL.DemoRequestSave, {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(response =>response)
        .then(result => {
            console.log(result);
            setIsLoading(false);
            reset();
            if(result.status === 200){
                cogoToast.success(<p>Your request successfully summited. Please check your mail for more details</p>, {
                    position: 'top-right'
                });
            }
            setSubmitSuccess(true);
        });
    }

        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header
                    closeButton
                    className="py-0 pt-3 border-0 pe-4"
                ></Modal.Header>
                <h2 className="text-blue text-center mb-0 mx-auto">Get Demo</h2>

                <Modal.Body className="px-4">
                    <form className="info-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="input-group mb-4 d-flex flex-column">
                            <input
                                type="text"
                                className="form-control px-3 w-100"
                                name="name"
                                autoComplete="off"
                                id="exampleInputName"
                                placeholder="Enter your name"
                                {...register("full_name", {
                                    required: "Full name is required",
                                })}
                                onChange={(e)=>handleNameChange(e.target.value)}
                            />
                            <p className="text-danger mb-0"> {errors.full_name ? "*" : ""}{errors.full_name?.message}</p>
                            <p className="text-danger mb-0" style={{ display: !nameErrorMsg ? 'none' : 'block' }}>*Must be valid your name</p>
                        </div>
                        <div className="input-group mb-4 d-flex flex-column">
                            <input
                                type="email"
                                className="form-control px-3 w-100"
                                name="email"
                                id="exampleInputEmail1"
                                placeholder="Email"
                                autoComplete="off"
                                {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                        value: /^\S+@\S+$/i,
                                        message: "This is not a valid email",
                                    },
                                })}
                                onChange={(e)=>handleChange(e.target.value)}
                            />
                            {error?<p style={{color: "red"}}>Email is invalid yet, please check</p>:''}
                            <p className="text-danger mb-0"> {errors.email ? "*" : ""}{errors.email?.message}</p>
                            <p className="text-danger mb-0" style={{ display: !emailErrorMsg ? 'none' : 'block' }}>*Must be valid your company email</p>
                        </div>
                        <div className="input-group mb-4 d-flex flex-column">
                            <Controller
                                control={control}
                                name="phone"
                                rules={{ required: true }}
                                render={({ field: { ref, ...field } }) => (
                                    <PhoneInput
                                        {...field}
                                        inputExtraProps={{
                                            ref,
                                            required: true,
                                            autoFocus: true
                                        }}
                                        inputStyle={{
                                            background: "lightblue",
                                            height: "50px",
                                            width: "100%",
                                            color: "#163560"
                                        }}
                                        placeholder="Enter phone number"
                                        inputProps={{
                                            maxLength: 18, // Set maximum length to 11 characters
                                        }}
                                        enableSearch
                                        countryCodeEditable={false}
                                        country={"us"}
                                        preferredCountries={['us','bd','br','py']}
                                    />
                                )}
                            />
                            <p className="text-danger mb-0"> {errors.phone ? "* Phone number is required" : ""}{errors.phone?.message}</p>
                        </div>
                       {/* <div className="input-group mb-4 d-flex flex-column">
                            <input
                                type="tel"
                                className="form-control px-3 w-100"
                                name="number"
                                id="exampleInputEmail1"
                                placeholder="Mobile Number"
                                autoComplete="off"
                                {...register("phone", {
                                    required: "Phone Number is required",

                                    pattern: {
                                        value: /^\+?[0-9]+$/,
                                        message: "This is not a phone number",
                                    },

                                    maxLength: {
                                        value: 15,
                                        message: "Maximum 15 digit allow"
                                    },

                                    minLength: {
                                        value: 9,
                                        message: "Minimum 9 digit need"
                                    }
                                })}
                            />
                            <p className="text-danger mb-0"> {errors.phone ? "*" : ""}{errors.phone?.message}</p>
                        </div>*/}
                        <div className="input-group mb-4 d-flex flex-column">
                            <input 
                                type="text"
                                className="form-control px-3 w-100"
                                name="company_name"
                                id="exampleInputEmail1"
                                placeholder="Company name"
                                autoComplete="off"
                                {...register("company_name", {
                                    required: "Company Name is required",
                                })}
                            />
                            <p className="text-danger mb-0"> {errors.company_name ? "*" : ""}{errors.company_name?.message}</p>
                        </div>
                        <div className="input-group mb-4 d-flex flex-column">
                            <input
                                type="text"
                                className="form-control px-3 w-100"
                                name="Designation"
                                id="exampleInputEmail1"
                                placeholder="Designation"
                                autoComplete="off"
                                {...register("designation", {
                                    required: "Designation is required",
                                })}
                                onChange={(e) =>designationNameChange(e.target.value)}
                            />
                            <p className="text-danger mb-0"> {errors.designation ? "*" : ""}{errors.designation?.message}</p>
                            <p className="text-danger mb-0" style={{ display: !designationErrorMsg ? 'none' : 'block' }}>*Must be valid your designation</p>
                        </div>
                        <div className="input-group mb-4 d-flex flex-column">
                            <select 
                            className="form-select px-3 w-100" 
                            name="product_name"
                            {...register("product_name", {
                                required: "Product name is required",
                            })}
                            >
                                <option selected>--Select Product--</option>
                                {productes.map((item) => (
                                    <option key={item.id} value={item.title}>
                                        {item.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <input type="hidden" {...register("section")} value={section} />
                        <input type="hidden" {...register("topic_name")} value={topic} />

                        <div className="text-end mt-3 mb-3">
                        {!error && !emailErrorMsg && !nameErrorMsg && !designationErrorMsg
                                ?<button type="submit" className="btn btn-orange px-5">
                                {isLoading && <p>Processing...</p>}
                                Submit {submitSuccess && <BsCheck2All style={{color: "green", fontSize: "28px"}}/>}
                                </button>
                            
                                :<button className="btn btn-orange px-5" disabled> Submit </button>
                            }
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        );
};

    export default DemoModal;
