import React from "react";
import { Modal } from "react-bootstrap";

const LogInModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header
        closeButton
        className="py-0 pt-3 border-0 pe-4"
      ></Modal.Header>
      <h2 className="text-blue text-center mb-0 mx-auto">Login</h2>

      <Modal.Body className="px-4">
        <form className="info-form">
          <div className="input-group mb-4">
            <input
              type="email"
              className="form-control px-3"
              name="email"
              id="exampleInputEmail1"
              placeholder="Enter Email"
              required
            />
          </div>
          <div className="input-group mb-4">
            <input
              type="password"
              className="form-control px-3"
              name="password"
              id="password"
              placeholder="Enter Password"
              required
            />
          </div>
          <div className="text-end mt-3 mb-3">
            <button
              style={{ height: "50px" }}
              type="submit"
              className="btn btn-orange w-100"
            >
              Login
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default LogInModal;
