
class ApiURL {
   //static BaseURL = "http://localhost:8000/api/";
   static BaseURL = "https://backend.aspiretss.com/api/";

   static GetProduct = this.BaseURL+"get-product-api";
   static DemoRequestSave = this.BaseURL+"request-for-demo-save";
   static OurEvent = this.BaseURL+"our-event-api";
   //static OurEventByCategoryID = this.BaseURL+"OurEventByCategoryID";
   static Category = this.BaseURL+"get-category-api";
   static SeeMoreCategoryApi =this.BaseURL+"SeeMoreCategory";

}

export default ApiURL