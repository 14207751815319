import React, {useState} from "react";
import { Modal } from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import cogoToast from "cogo-toast";
import PhoneInput from "react-phone-input-2";
import {BsCheck2All} from "react-icons/bs";

const RegisterModal = ({ show, handleClose }) => {

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm();

  const [error,setError]=useState();
  const [isLoading, setIsLoading] = useState(false);
  const [nameErrorMsg, setNameErrorMsg]=useState(false);
  const [lastNameErrorMsg, setLastNameErrorMsg]=useState(false);
  const [emailErrorMsg, setEmailErrorMsg]=useState(false);
  const [designationErrorMsg, setDesignationErrorMsg]=useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg]=useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const onSubmit = data => {
    //console.log(data);
    setIsLoading(true);
    // fetch('http://localhost:8000/api/register-now', {
    fetch('https://backend.aspiretss.com/api/register-now', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
        .then(response =>response)
        .then(result => {
          // console.log(result);
          setIsLoading(false);
          reset();
          if(result.status === 200){
            cogoToast.success(<p>You are successfully registered</p>, {
              position: 'top-right'
            });
          }
        });
  }

const handleNameChange =(val)=>{
    if(val.match(/^([a-zA-Z\s*$/.]{2,})$/)){      //For empty space  \s*$/
        setNameErrorMsg(false);
    }else{
        setNameErrorMsg(true);
    }
}
const handleLastNameChange =(val)=>{
    if(val.match(/^([a-zA-Z\s*$/.]{2,})$/)){      //For empty space  \s*$/
        setLastNameErrorMsg(false);
    }else{
        setLastNameErrorMsg(true);
    }
}

const handleEmailChange =(val)=>{
  let emailChk = /@(gmail\.com|hotmail\.com|outlook\.com|aol\.com|proton\.com|protonmail\.com|zohomail\.com|gmx\.com|gmxmail\.com|yahoo\.com|mail2world\.com|icloud\.com|tuta\.com|10minutemail\.com|juno\.com|mailtrap\.com)$/;
  if(val.match(emailChk)){
      setEmailErrorMsg(true);
  }else{
      setEmailErrorMsg(false);
  }
  if(val.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/)){
      setError(false);
  }else{
      setError(true);
  }
}
const designationNameChange =(val)=>{
  if(val.match(/^([a-zA-Z\s*$/.-]{2,})$/)){      //For empty space  \s*$/
      setDesignationErrorMsg(false);
  }else{
      setDesignationErrorMsg(true);
  }
}


const handlePasswordChange =(val)=>{
  if(val.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,40}$/)){      //For empty space  \s*$/
      setPasswordErrorMsg(false);
  }else{
    setPasswordErrorMsg(true);
  }
}


  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header
        closeButton
        className="py-0 pt-3 border-0 pe-4"
      ></Modal.Header>
      <h2 className="text-blue text-center mb-0 mx-auto">Register Now</h2>

      <Modal.Body className="px-4">
        <form className="info-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="input-group mb-4">
            <input
              type="text"
              className="form-control px-3 w-100"
              name="first_name"
              autoComplete="off"
              id="exampleInputName"
              placeholder="First name"
              {...register("first_name", {
                required: "Full name is required",
              })}
              onChange={(e)=>handleNameChange(e.target.value)}
            />
            <p className="text-danger mb-0"> {errors.first_name ? "*" : ""}{errors.first_name?.message}</p>
            <p className="text-danger mb-0" style={{ display: !nameErrorMsg ? 'none' : 'block' }}>*Must be valid your name</p>
          </div>
          <div className="input-group mb-4">
            <input
                type="text"
                className="form-control px-3 w-100"
                name="last_name"
                autoComplete="off"
                id="exampleInputName"
                placeholder="Last name"
                {...register("last_name", {
                  required: "Full name is required",
                })}
                onChange={(e)=>handleLastNameChange(e.target.value)}
            />
             <p className="text-danger mb-0"> {errors.last_name ? "*" : ""}{errors.last_name?.message}</p>
             <p className="text-danger mb-0" style={{ display: !lastNameErrorMsg ? 'none' : 'block' }}>*Must be valid your name</p>
          </div>
          <div className="input-group mb-4">
            <input
              type="email"
              className="form-control px-3 w-100"
              name="email"
              id="exampleInputEmail1"
              placeholder="Email"
              autoComplete="off"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "This is not a valid email",
                },
              })}
              onChange={(e)=>handleEmailChange(e.target.value)}
            />
              {error?<p style={{color: "red"}}>Email is invalid yet, please check</p>:''}
              <p className="text-danger mb-0"> {errors.email ? "*" : ""}{errors.email?.message}</p>
              <p className="text-danger mb-0" style={{ display: !emailErrorMsg ? 'none' : 'block' }}>*Must be valid your company email</p>
          </div>
          <div className="input-group mb-4">
            <input
                type="text"
                className="form-control px-3 w-100"
                name="company_name"
                autoComplete="off"
                id="exampleInputName"
                placeholder="Company name"
                {...register("company_name", {
                  required: "Company Name is required",
                })}
            />
              <p className="text-danger mb-0"> {errors.company_name ? "*" : ""}{errors.company_name?.message}</p>
          </div>
          <div className="input-group mb-4">
            <input
                type="text"
                className="form-control px-3 w-100"
                name="designation"
                autoComplete="off"
                id="exampleInputName"
                placeholder="Designation"
                {...register("designation", {
                  required: "Designation is required",
                })}
                onChange={(e) =>designationNameChange(e.target.value)}
            />
            <p className="text-danger mb-0"> {errors.designation ? "*" : ""}{errors.designation?.message}</p>
            <p className="text-danger mb-0" style={{ display: !designationErrorMsg ? 'none' : 'block' }}>*Must be valid your designation</p>
          </div>
          <div className="input-group mb-4">
            <input
                type="password"
                className="form-control px-3 w-100"
                name="password"
                autoComplete="off"
                id="exampleInputName"
                placeholder="password"
                {...register("password", {
                  required: "Password is required",
                })}
                onChange={(e) =>handlePasswordChange(e.target.value)}
            />
            <p className="text-danger mb-0"> {errors.password ? "*" : ""}{errors.password?.message}</p>
            <p className="text-danger mb-0" style={{ display: !passwordErrorMsg ? 'none' : 'block' }}>*Must be at least one lowercase letter, one uppercase letter, one numeric digit, and one special character</p>
          </div>
          <div className="input-group mb-4">
            <Controller
                control={control}
                name="phone"
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                    <PhoneInput
                    {...field}
                    inputExtraProps={{
                        ref,
                        required: true,
                        autoFocus: true
                    }}
                    country={"us"}
                    inputStyle={{
                        background: "lightblue",
                        height: "50px",
                        width: "100%",
                        color: "#163560"
                    }}
                    placeholder="Phone number"
                    inputProps={{
                        maxLength: 18, // Set maximum length to 11 characters
                    }}
                    enableSearch
                    countryCodeEditable={false}
                    preferredCountries={['us','bd','br','py']}
                />

                )}
            />
            <p className="text-danger mb-0"> {errors.phone ? "*Phone is required" : ""}{errors.phone?.message}</p>
           {/* <input
                type="text"
                className="form-control px-3"
                name="phone"
                autoComplete="off"
                id="exampleInputName"
                placeholder="phone"
                {...register("phone", {
                  required: "Password is required",
                })}
            />*/}
          </div>
          <div className="text-end mt-3 mb-3">
            {!error && !emailErrorMsg && !nameErrorMsg  && !lastNameErrorMsg
                ?<button type="submit" className="btn btn-orange px-5">
                {isLoading && <p>Processing...</p>}
                Submit {submitSuccess && <BsCheck2All style={{color: "green", fontSize: "28px"}}/>}
                </button>
                :<button className="btn btn-orange px-5" disabled> Submit </button>
            }
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default RegisterModal;
