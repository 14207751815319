import React from 'react';
import bannerImg from '../../../assets/images/banner-img.webp';
import { useState } from 'react';
import WatchVideoModal from '../../modal/WatchVideoModal';
import RegisterModal from '../../modal/RegisterModal';
import InnerSideBar from '../../layout/InnerSideBar';

const BannerSection = ({ banner }) => {
  const [isVideoOpen, setVideoOpen] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const bannarSection = { paddingTop: "140px", }

  return (
    <>
      <div className="inner-banner px-4">
        <div className="container">
          <div className="row flex-column-reverse flex-lg-row align-items-center" style={bannarSection}>
            <div className="col-lg-7 banner-content">
              <h1 className="banner-title h3">{banner.heading}</h1>

              <p className="font-14 max-475 ">{banner.desc}</p>
              
            </div>
            <div className="col-lg-5 banner-image">
              <figure>
                <img src={bannerImg} alt="banner-img" className="img-fluid" />
              </figure>
            </div>
          </div>
        </div>
        <InnerSideBar />
      </div>
      {showRegisterModal && (
        <RegisterModal
          show={showRegisterModal}
          handleClose={() => setShowRegisterModal(false)}
        />
      )}
      {isVideoOpen && (
        <WatchVideoModal
          show={isVideoOpen}
          handleClose={() => setVideoOpen(false)}
          link={'https://youtu.be/M3Lx_oE_n04'}
        />
      )}
    </>
  );
};

export default BannerSection;
