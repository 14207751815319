import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { MdArrowRight } from 'react-icons/md';
import { SiDatabricks } from 'react-icons/si';
import { managedServices } from '../../data/mainNavData';

const ManagedNavItem = ({ navBlue, expanded , show , statefunc, clear  }) => {
  const {
    SOCaas,
    MSSP,
    managedIT,
    cyberSecurity,
    vCISO,
    cloudSecurity,
    digitalForensic,
    consultancy,
  } = managedServices;

  const [activeDropDown, setActiveDropDown] = useState('dropdownItem1');
  return (
    <>
      <Dropdown onClick={navBlue} 
       show={show}
      className="managedIT me-3">
       
        <span
        onClick={()=>{  
          clear();
          statefunc(!show); 
          }
        }
        >
          <Dropdown.Toggle variant="transparent" className="dropdown-title">
            Managed Services
          </Dropdown.Toggle>
        </span>
        <div>
          <Dropdown.Menu className="p-0 main-dropdown">
            <Dropdown
              show={activeDropDown === 'dropdownItem1' ? true : false}
              onClick={() => {
                setActiveDropDown('dropdownItem1');
              }}
            >
              <Dropdown.Toggle
                variant="transparent"
                className={`${
                  activeDropDown === 'dropdownItem1' && 'bg-orange'
                } toggle-btn active py-2-7 w-100 text-white d-flex align-items-center justify-content-between`}
              >
                <div className="flex-grow-1 text-center">SOCaas Services</div>{' '}
                <MdArrowRight />
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0 innerDropDown dropdownItem1">
                <h4 className="text-white text-center pt-3">
                  <SiDatabricks className="mb-1 text-white" /> SOCaas Services
                </h4>
                <ul className="options px-3 px-md-4 pt-0 pb-3 font-16">
                  <div>
                    {SOCaas.map((item, index) => (
                      <li onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }} key={index}>
                        <Link to={`${item.link}`}
                         className='menuItem'
                         onClick={() => {
                           expanded(expanded ? false : 'expanded');
                         }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              show={activeDropDown === 'dropdownItem2' ? true : false}
              onClick={() => {
                setActiveDropDown('dropdownItem2');
              }}
            >
              <Dropdown.Toggle
                variant="transparent"
                className={`${
                  activeDropDown === 'dropdownItem2' && 'bg-orange'
                } toggle-btn active py-2-7 w-100 text-white d-flex align-items-center justify-content-between`}
              >
                <div className="flex-grow-1 text-center">MSSP Services</div>{' '}
                <MdArrowRight />
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0 innerDropDown dropdownItem2">
                <h4 className="text-white text-center pt-3">
                  <SiDatabricks className="mb-1 text-white" /> MSSP Services
                </h4>
                <ul className="options px-3 px-md-4 pt-0 pb-3 font-16">
                  <div>
                    {MSSP.map((item, index) => (
                      <li onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }} key={index}>
                        <Link to={`${item.link}`}
                         className='menuItem'
                         onClick={() => {
                           expanded(expanded ? false : 'expanded');
                         }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              show={activeDropDown === 'dropdownItem3' ? true : false}
              onClick={() => {
                setActiveDropDown('dropdownItem3');
              }}
            >
              <Dropdown.Toggle
                variant="transparent"
                className={`${
                  activeDropDown === 'dropdownItem3' && 'bg-orange'
                } toggle-btn active py-2-7 w-100 text-white d-flex align-items-center justify-content-between`}
              >
                <div className="flex-grow-1 text-center">Managed IT </div>{' '}
                <MdArrowRight />
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0 innerDropDown dropdownItem3">
                <h4 className="text-white text-center pt-3">
                  <SiDatabricks className="mb-1 text-white" /> Managed IT
                  Services
                </h4>
                <ul className="options px-3 px-md-4 pt-0 pb-3 font-16">
                  <div>
                    {managedIT.map((item, index) => (
                      <li onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }} key={index}>
                        <Link to={`${item.link}`}
                         className='menuItem'
                         onClick={() => {
                           expanded(expanded ? false : 'expanded');
                         }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              show={activeDropDown === 'dropdownItem4' ? true : false}
              onClick={() => {
                setActiveDropDown('dropdownItem4');
              }}
            >
              <Dropdown.Toggle
                variant="transparent"
                className={`${
                  activeDropDown === 'dropdownItem4' && 'bg-orange'
                } toggle-btn active py-2-7 w-100 text-white d-flex align-items-center justify-content-between`}
              >
                <div className="flex-grow-1 text-center">Cyber Security </div>{' '}
                <MdArrowRight />
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0 innerDropDown dropdownItem4">
                <h4 className="text-white text-center pt-3">
                  <SiDatabricks className="mb-1 text-white" /> Cyber Security
                  Services
                </h4>
                <ul className="options px-3 px-md-4 pt-0 pb-3 font-16">
                  <div>
                    {cyberSecurity.map((item, index) => (
                      <li onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }} key={index}>
                        <Link to={`${item.link}`}
                         className='menuItem'
                         onClick={() => {
                           expanded(expanded ? false : 'expanded');
                         }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              show={activeDropDown === 'dropdownItem5' ? true : false}
              onClick={() => {
                setActiveDropDown('dropdownItem5');
              }}
            >
              <Dropdown.Toggle
                variant="transparent"
                className={`${
                  activeDropDown === 'dropdownItem5' && 'bg-orange'
                } toggle-btn active py-2-7 w-100 text-white d-flex align-items-center justify-content-between`}
              >
                <div className="flex-grow-1 text-center">vCISO Services</div>{' '}
                <MdArrowRight />
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0 innerDropDown dropdownItem5">
                <h4 className="text-white text-center pt-3">
                  <SiDatabricks className="mb-1 text-white" /> vCISO Services
                </h4>
                <ul className="options px-3 px-md-4 pt-0 pb-3 font-16">
                  <div>
                    {vCISO.map((item, index) => (
                      <li onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }} key={index}>
                        <Link to={`${item.link}`}
                         className='menuItem'
                         onClick={() => {
                           expanded(expanded ? false : 'expanded');
                         }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              show={activeDropDown === 'dropdownItem6s' ? true : false}
              onClick={() => {
                setActiveDropDown('dropdownItem6s');
              }}
            >
              <Dropdown.Toggle
                variant="transparent"
                className={`${
                  activeDropDown === 'dropdownItem6s' && 'bg-orange'
                } toggle-btn active py-2-7 w-100 text-white d-flex align-items-center justify-content-between`}
              >
                <div className="flex-grow-1 text-center">Cloud Security</div>{' '}
                <MdArrowRight />
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0 innerDropDown dropdownItem6s">
                <h4 className="text-white text-center pt-3">
                  <SiDatabricks className="mb-1 text-white" /> Cloud Security
                  Services
                </h4>
                <ul className="options px-3 px-md-4 pt-0 pb-3 font-16">
                  <div>
                    {cloudSecurity.map((item, index) => (
                      <li onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }} key={index}>
                        <Link to={`${item.link}`}
                         className='menuItem'
                         onClick={() => {
                           expanded(expanded ? false : 'expanded');
                         }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              show={activeDropDown === 'dropdownItem7' ? true : false}
              onClick={() => {
                setActiveDropDown('dropdownItem7');
              }}
            >
              <Dropdown.Toggle
                variant="transparent"
                className={`${
                  activeDropDown === 'dropdownItem7' && 'bg-orange'
                } toggle-btn active py-2-7 w-100 text-white d-flex align-items-center justify-content-between`}
              >
                <div className="flex-grow-1 text-center">Digital Forensic</div>{' '}
                <MdArrowRight />
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0 innerDropDown dropdownItem7">
                <h4 className="text-white text-center pt-3">
                  <SiDatabricks className="mb-1 text-white" /> Digital Forensic
                  Services
                </h4>
                <ul className="options px-3 px-md-4 pt-0 pb-3 font-16">
                  <div>
                    {digitalForensic.map((item, index) => (
                      <li onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }} key={index}>
                        <Link to={`${item.link}`}
                         className='menuItem'
                         onClick={() => {
                           expanded(expanded ? false : 'expanded');
                         }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              show={activeDropDown === 'dropdownItem8' ? true : false}
              onClick={() => {
                setActiveDropDown('dropdownItem8');
              }}
            >
              <Dropdown.Toggle
                variant="transparent"
                className={`${
                  activeDropDown === 'dropdownItem8' && 'bg-orange'
                } toggle-btn py-2-7 w-100 text-white d-flex align-items-center justify-content-between`}
              >
                <div className="flex-grow-1 text-center">Consultancy </div>{' '}
                <MdArrowRight />
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0 innerDropDown dropdownItem8">
                <h4 className="text-white text-center pt-3">
                  <SiDatabricks className="mb-1 text-white" /> Consultancy
                  Services
                </h4>
                <ul className="options px-3 px-md-4 pt-0 pb-3 font-16">
                  <div>
                    {consultancy.map((item, index) => (
                      <li onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }} key={index}>
                        <Link to={`${item.link}`}
                         className='menuItem'
                         onClick={() => {
                           expanded(expanded ? false : 'expanded');
                         }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </div>
      </Dropdown>
    </>
  );
};

export default ManagedNavItem;
