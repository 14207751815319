import image1 from '../../assets/images/inner/Company/careers/hiring.png';
import image2 from '../../assets/images/inner/Company/careers/flexible.png';
import image3 from '../../assets/images/inner/Company/careers/parttime.png';
import image4 from '../../assets/images/inner/Company/careers/opportunities.png';
import image5 from '../../assets/images/inner/Company/careers/team.png';
import image6 from '../../assets/images/inner/Company/careers/flexible-pic.png';
import image7 from '../../assets/images/inner/Company/careers/intership.png';
import image12 from '../../assets/images/inner/Company/careers/apply-process.png';
import image8 from '../../assets/images/inner/Company/careers/sales.png';
import image9 from '../../assets/images/inner/Company/careers/web-developer.png';
import image10 from '../../assets/images/inner/Company/careers/graphics.png';
import image11 from '../../assets/images/inner/Company/careers/soc.png';
import image13 from '../../assets/images/inner/Company/careers/ciso.png';

export const CareersData = {

    banner: {
        heading: 'Job Opportunities',
        tagLine: '',
    },
    section1: {
        headding: `WE ARE HIRING!`,
        content: `We are searching for talented people to join our team!
        <br><br>
        Aspire Tech, founded in 2011, provides enterprise services and technology solutions to monitor and enhance their employees' security awareness and test their IT defenses.  Aspire Tech has over ten years of expertise in assisting businesses with IT security.Since the inception of the Internet, cyber thieves have essentially targeted the Swiss banking industry.As a result, we began performing penetration examinations in 2012 to examine the industry's IT infrastructure and identify relevant changes.With Aspire Tech, we created a one-of-a-kind solution that allows you to evaluate your security and help it improve against cyber threats on both the people and system sides at the same time. We pooled our total IT-security experience to develop templates and wizards that are both effective and simple to use.You may swiftly carry out a number of campaigns using this method, including phishing simulations, awareness training, technology evaluations, malware simulations, and simulated ransomware assaults. Customers include energy firms, financial institutions, government agencies, healthcare providers, and manufacturers, as well as other worldwide organizations.
        `,
        image: image1
    },
    section2: {
        content: [{
                headding: `Flexible `,
                content: `
                With flexible work hours!
                `,
                image: image2,
            },
            {
                headding: `Part time`,
                content: `
                Be a part of an exciting adventure at a successful and rapidly expanding firm.
                `,
                image: image3,

            },
            {
                headding: `Opportunities`,
                content: `Opportunities for Training and Development`,
                image: image4,
            },
            {
                headding: `Team`,
                content: `A wonderful and highly driven multicultural team`,
                image: image5,
            }
        ]
    },
    section3: {
        headding: 'Flexible working model',
        content: `Aspire Tech offers a flexible working environment.If you need to live balanced, healthy lives while also growing our Aspire Tech Way-based culture of cooperation. We believe in the ability of our Aspire Tech to accomplish their best job by promoting a progressive strategy that encourages both collaborative teaming and individual flexibility.        
        `,
        image: image6
    },
    section4: {
        headding: 'Internship Opportunities',
        content: `We can help you extend your education and skills with real-world job experience, working on essential projects for Aspire Tech, whether you are searching for a summer internship or a placement throughout the academic year.As a member of our community, you'll gain an insider's view of how we function and the individuals that make it all happen, our Aspire Tech.
        <br><br>
        Follow us on Facebook, Twitter, and Instagram to learn more about us, and click through to see our current openings.
                
        `,
        image: image7
    },
    section4image: {
        image: image12
    },
    section5: {
        headding: 'Join Our Sales Team',
        content: `
        Do you enjoy hunting?<br><br>
        Do you enjoy collaborating with other sales professionals and managers whose primary goal is to identify and close new customers?<br><br>
        If yes, keep reading!           
        `,
        image: image8

    },
    section5content: {
        content: `
        Aspire Tech is looking for outstanding people to help shape the future of local media sales.
        We give a fantastic product to sell, superior training tools, and a sales management system that promotes company development and rewards performance to our staff.
        We target thousands of local businesses with a laser-like concentration on certain company categories and introduce them to Aspire Tech's enormous demographically diverse consumers.
        We need problem solvers who are enthusiastic, dedicated, resourceful, organized, and customer-focused, with excellent communication skills and the ability to create client connections.
        <br><br>
        We are continuously looking for competent and passionate developers at all levels:
        <br><br>
        <ul class="mt-5 ma-4">
            <li>Junior Sales Executive </li>
            <li>Senior Sales Executive</li>
            <li>Sales Manager</li>
            <li>Sales Director</li>
        </ul>   
        <a class="btn btn-primary text-white mt-4" href="">Apply Now</a>    
        `,
    },
    section6: {
        headding: 'Join Our Web Development Team',
        content: `
        Join our award-winning Development Team and contribute to the realization of our amazing design work.<br><br>
        We are searching for IT, engineering, or technically inclined graduates who are eager to learn new technologies and want to take their initial steps toward becoming a full-fledged web and app developer.
           
        `,
        image: image9

    },
    section6content: {
        content: `
        We value your potential, passion, and charisma just as much as your technical talents.
        <br><br>
        We are continuously looking for competent and passionate developers at all levels:
        <br><br>
        <ul class="mt-5 ma-4">
            <li>Graduate developers</li>
            <li>Mid-level developers</li>
            <li>Senior developers</li>
        </ul>   
        <a class="btn btn-primary text-white mt-4" href="">Apply Now</a>    
        `,
    },
    section7: {
        headding: 'Join Our  Motion Graphic Designer Team',
        content: `
        We're looking for a Motion Graphic Designer to join our creative team and work on a range of architecture-related material, including 2D animation, video production, graphics, short movies, research, and so on.You will play a key part in our content team as a Motion Graphic Designer, providing daily material for the Aspire Tech website and social media channels. 
        `,
        image: image10
    },
    section7content: {
        content: `
        You will also assist in the development and implementation of new processes to drive operational excellence within the Editorial Team.<br><br>
        We are continuously looking for competent and passionate Motion Graphic Designer at all levels:
            
        <ul class="mt-5 ma-4">
            <li>Mid-level Motion Graphic Designer </li>
            <li>Senior Motion Graphic Designer</li>
        </ul>   
        <a class="btn btn-primary text-white mt-4" href="">Apply Now</a>    
        `,
    },
    section8: {
        headding: 'Join Our SOC Team',
        content: `
        If you want to be the difference between a terrible and a good day, have a professional approach, and a passion for cybersecurity, you could be exactly what we're searching for.View our current job openings in our Security Operations Center (SOC) and Engineering Teams.
        `,
        image: image11
    },
    section8content: {
        content: `
        Chief Information Security Officer Responsibilities:
        
        <ul class="mt-5 ma-4">
        <li>Junior Cyber Security Engineer</li>
        <li>Junior Security Analyst Engineer</li>
        <li>Cyber Security Engineer</li>
        <li>Senior Security Analyst Engineer</li>
        <li>Senior Information Security Engineer</li>
        <li>Pre-Sales Executive </li>
        </ul>   
        <a class="btn btn-primary text-white mt-4" href="">Apply Now</a>    
        `,
    },
    section9: {
        headding: 'Join As CISO',
        content: `
        To create and carry out a thorough information security strategy for our business, we are looking for a highly motivated chief information security officer. Additionally, you will make sure the IT security system complies with legal standards and assess computer system hazards in real-time.`,
        image: image13
    },
    section9content: {
        content: `
        <h3 class="mt-5">Chief Information Security Officer Responsibilities:</h3>        
        <ul class="mt-3 ma-4">
            <li>Working with all business units to determine possible risks and risk management processes.</li>
            <li>Business development planning and acquiring the correct technology.</li>
            <li>Analyzing IT security threats in real-time and mitigating these threats.</li>
            <li>Ensuring that newly-acquired technology complies with the IT security regulations.</li>
            <li>Planning, designing, and implementing an IT and network strategy for the company.</li>
            <li>Managing the continuous maintenance of the IT network to ensuring optimum security levels are maintained.</li>
            <li>Sourcing the necessary hardware and software to implement the IT strategy, and negotiating contracts.</li>
            <li>Ensuring that no internal breaches or misuse of data take place.</li>
            <li>Determining the cause of internal and external data breaches and instituting appropriate corrective action.</li>
            <li>Presenting regular feedback reports on IT network security to the board of directors.</li>
        </ul>   
        <br>
        <h3 lass="mt-5">Chief Information Security Officer Requirements:</h3>    
        <ul class="mt-3 ma-4">
            <li>A bachelor's degree in computer science, information technology, or a related field. An MBA is preferable.</li>
            <li>A minimum of seven years' experience in risk management, information security, or programming.</li>
            <li>An exceptional understanding of scripting and source code programming languages, such as C#, C++, .NET, and Java.</li>
            <li>Knowledge of information security management frameworks, such as ISO/IEC 27001 and NIST.</li>
            <li>Experience in financial forecasting and budget management.</li>
            <li>Outstanding negotiation skills for negotiating contracts and IT support services to be rendered.</li>
            <li>Excellent understanding of current legislation and regulations relevant to our organization.</li>
            <li>Excellent project management and leadership skills.</li>
            <li>First-rate written and verbal communication skills.    </li>
        </ul>   
        <a class="btn btn-primary text-white mt-4" href="">Apply Now</a>      
        `,
    },
};