import React from "react";
import { ReactComponent as ScrollArrow } from "../../assets/icons/arrow-top.svg";

export default function MoveToTop() {
  // Top: 0 takes us all the way back to the top of the page
  // Behavior: smooth keeps it smooth!
  const MoveToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //scroll-to-top classes: fixed, bottom:0, right:0
  return (
    <>
      <button className="btn p-0 scroll-top" style={{position:"absolute",bottom: "20px", right:"30px"}} onClick={MoveToTop}>
        <ScrollArrow />
        <div className="text-white">Go to top</div>
      </button>
    </>
  );
}
