import React from "react";
import { Nav } from "react-bootstrap";
import ScrollspyNav from "react-scrollspy-nav";
import webinar from "../../assets/icons/webinar.svg";
import avatar from "../../assets/icons/avatar.svg";

const InnerSideBar = () => {
  const NavItem = [
    { id: 1, link: "/resources/webinars", text: "Webinars", icon: webinar },
    {
      id: 2,
      link: "/resources/training",
      text: "Trainings",
      icon: avatar,
    },
  ];

  const targetIds = NavItem.map((item) => item.link);

  return (
    <aside id="inner-sidebar">
      <div className="sidebar-container">
        {/* <ScrollspyNav
          scrollTargetIds={targetIds}
          activeNavClass="is-active"
          scrollDuration="100"
        > */}
          <div className="d-flex align-items-end flex-column gap-3">
            {NavItem.map((item) => {
              const { id, link, icon, text } = item;
              return (
                <Nav.Link
                  href={link}
                  className="sidebar-item p-0 ml-auto"
                  key={id}
                >
                  <div className="d-flex align-items-center p-2 p-sm-3">
                    <figure className="m-0">
                      <img src={icon} alt={text} className={`icon icon${id}`} />
                    </figure>
                    <div className="text-white font-18 fw-bold ms-2">{text}</div>
                  </div>
                </Nav.Link>
              );
            })}
          </div>
        {/* </ScrollspyNav> */}
      </div>
    </aside>
  );
};

export default InnerSideBar;
