import AppRouter from './AppRouter';
import ScrollToTop from './components/layout/ScrollToTop';
function App() {
  return (
    <>
      <ScrollToTop />
      <AppRouter />
    </>
  );
}

export default App;
