import React from "react";
import Phone from "../../assets/icons/phone.svg";
import Envelope from "../../assets/icons/envelope.svg";
import Location from "../../assets/icons/location.svg";

const ContactDetails = ({ contactData }) => {

  return (
    <>
      {contactData.map((contact) => (
        <div key={contact.id} className="col-sm-6 mb-4">
          <div className="d-flex gap-3 align-items-center mb-2">
            <h5 className="" style={{color: "#163560"}}>{contact.office}</h5>
            <div style={{ width: "30px" }} className="line-42 text-blue"></div>
          </div>
          <div className="text-blue font-14 fw-600">
            <div className="d-flex gap-3 mb-1">
              <figure className="m-0">
                <img src={Phone} alt="phone-icon" />
              </figure>
              {contact.phone}
            </div>
            <div className="d-flex gap-3 mb-1">
              <figure className="m-0">
                <img src={Envelope} alt="phone-icon" />
              </figure>{" "}
              {contact.email}
            </div>
            <div className="d-flex gap-3 mb-1">
              <figure className="m-0">
                <img src={Location} alt="phone-icon" />
              </figure>
              <div className="">{contact.location}</div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ContactDetails;
