import React from 'react'
import { Helmet } from 'react-helmet'
import BannerSection from './frames/BannerSectionWithoutBtn'
import {Col, Card, Container, Row, Image} from 'react-bootstrap';
import SideNavHeader from './SideNavHeader';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import {BsFillClockFill} from "react-icons/bs";
import {IoLogoFacebook} from "react-icons/fa";
import Facebook from "../../assets/images/facebook.png"
import LinkedIn from "../../assets/images/in.png"
import Twitter from "../../assets/images/twitter.png"

const EventDetails = () => {

    const [dataEvent, setDataEvent] = useState([]);
    useEffect(() => {
         //fetch(`http://localhost:8000/api/our-event-api/`)
         fetch(`https://backend.aspiretss.com/api/our-event-api/`)
            .then(res=>res.json())
            .then(data => setDataEvent(data));
    }, []);

    let error=0;
    const location = useLocation();
    const path = location.pathname;
    const title = decodeURIComponent(path.split('/').pop());
    const filteredData = dataEvent.filter((data) => {
        const cleanTitle = data.title.endsWith('?') ? data.title.slice(0, -1) : data.title;
        console.log(data);
        return cleanTitle === title;
    });

    const data = {
        meta: {
            title: 'Event',
            desc: ``,
        },
        banner: {
            heading: 'Event',
            tagLine: '',
        },
    };
    const cardStyle = { position: "relative", display: "flex", flexDirection: "column", minWidth: "0", wordWrap: "break-word", backgroundColor: "#fdfdfd", backgroundClip: "border-box", border: "1px solid rgb(234 243 246)", borderRadius: "0rem", marginTop: "15px" }

    return (
        <>
            <Helmet>
                <title>{data.meta.title}</title>
                <meta name="description" content={data.meta.desc} />
                <meta name="keywords" content="" />
            </Helmet>
            <BannerSection banner={data.banner} />{' '}
            <Container  className="h-100 mt-5 mb-4">
                <Row>
                    <Col xs={12} lg={12} className="bg-green order-lg-1 h-100">
                        <Card shadow="sm" className="rounded-md" style={cardStyle}>
                            {filteredData.length > 0 ? (
                                // render blog post content
                                <div>
                                    <div>
                                        <Row className="">
                                            <Col xs sm md lg xl xxl="12">
                                                <div className="d-flex">
                                                    <h1 className='px-4 pb-0 pt-5 text-dark' style={{fontSize: "34px"}}>{filteredData[0].title}</h1>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="">
                                            <Col xs sm md lg xl xxl="12">
                                                <div className="d-flex">
                                                    <p className="text-dark d-flex gap-2 pt-2 px-4" style={{fontSize: "18px"}}>
                                                        <BsFillClockFill size={25} className="items-center pt-2" />  {filteredData[0].date} | {filteredData[0].time}
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="">
                                            <Col xs sm md lg xl xxl="12">
                                                <div className="d-flex">
                                                    <p className="text-dark d-flex gap-2 pt-2 px-4" style={{fontSize: "18px"}}>
                                                        <a href="https://www.facebook.com/aspiretss" target="_blank"> <img src={Facebook} style={{height: "37px"}} alt="Facebook"/> </a>
                                                        <a href="https://www.linkedin.com/company/aspire-tech-services-solutions-ltd/" target="_blank"><img src={LinkedIn} style={{height: "29px", marginTop: "4px", borderRadius: "2px"}} alt="LinkedIn"/></a>
                                                        <a href="https://www.linkedin.com/company/aspire-tech-services-solutions-ltd/" target="_blank"><img src={Twitter} style={{height: "37px"}} alt="Twitter"/></a>
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs sm md lg xl xxl="12">
                                                <Image src={filteredData[0].file_name} height="540px" width="100%" alt="No way!" className="rounded-md"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs sm md lg xl xxl="12">
                                                <p className='text-dark text-justify p-4 pb-0' dangerouslySetInnerHTML={{ __html: filteredData[0].description }} />
                                            </Col>
                                        </Row>
                                    </div>
                                    {/* <div className='d-flex flex-wrap justify-content-between align-items-center my-5'>
                    <button type="button" className ="btn btn-primary">Previous</button>
                    <button type="button" className ="btn btn-primary">Next</button>
                    </div> */}
                                </div>

                            ) : (
                                // render a "post not found" message
                                <div className="p-4 mt-3 rounded-2 d-flex align-items-center justify-content-between" >
                                    <p>No Data Found</p>
                                   {/* <Link to={`/resources/blog/`}  type="button" className ="btn btn-primary">Go Back</Link>*/}
                                </div>
                            )}
                        </Card>
                    </Col>
                </Row>

            </Container>
        </>

    )
}

export default EventDetails