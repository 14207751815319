import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.min.css';
import './scss/main.scss';
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';

ReactDOM.render(
 // <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <script src="//code.tidio.co/imz0kxy1ksbbdzeovnyo3os7nloi4las.js" async></script>
      </Helmet>
      <BrowserRouter>
        <App />
      </BrowserRouter>,
    </HelmetProvider>,
 // </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
