import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import ContactDetails from "./ContactDetails";
import map from "../../assets/images/map.webp";
import allMap from "../../assets/images/all.webp";
import asiaMap from "../../assets/images/asia.webp";
import europeMap from "../../assets/images/europe.webp";
import usaMap from "../../assets/images/usa.webp";
import { useState } from "react";
import {useForm} from "react-hook-form";
import cogoToast from "cogo-toast";


const GetInTouch = ({ contactData }) => {
  // const asiaContactInfo = contactData.filter(
  //   (item) => item.continental.toLowerCase() === "asia"
  // );
  // const europeContactInfo = contactData.filter(
  //   (item) => item.continental.toLowerCase() === "europe"
  // );
  const usaContactInfo = contactData.filter(
    (item) => item.continental.toLowerCase() === "usa"
  );

  const [mapImage, setMapImage] = useState(map);
  const [error, setError]=useState();
  const [customeError, setCustomError]=useState();
  const [nameValidation, setNameValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm();

  const nameValidity =(val)=>{
      if(val.match(/^([a-zA-Z\s*$/.-]{2,})$/)){setNameValidation(false);}else{setNameValidation(true);}
    //   if(val) {
    //     var test = (x) => !isNaN(x);
    //     var check = (x, y, i) => x + i === y;
     
    //     for(var i = 0; i < val.length - 2; i++) {
    //       if(test(val[i])) {
    //          if(test(val[i + 1]) && test(val[i + 2])) {
    //            if(check(Number(val[i]),Number(val[i + 1]), 1) &&
    //              check(Number(val[i]), Number(val[i + 2]), 2)) {
    //             //  return false;
    //             console.log('false');
    //             setNameValidation(false)
    //            }
    //          }
    //       } else if(!test(val[i + 1]) && !test(val[i + 2])) {
    //          if(check(val.charCodeAt(i), val.charCodeAt(i + 1), 1) &&
    //              check(val.charCodeAt(i), val.charCodeAt(i + 2), 2)) {
    //               console.log('falseeee');
    //             setNameValidation(false)
    //            }
    //       }
    //       setNameValidation(true)
    //     }
       
    //  }
  }
  const handleChange =(val)=>{
    let emailChk = /@(gmail\.com|hotmail\.com|outlook\.com|aol\.com|proton\.com|protonmail\.com|zohomail\.com|gmx\.com|gmxmail\.com|yahoo\.com|mail2world\.com|icloud\.com|tuta\.com|10minutemail\.com|juno\.com|mailtrap\.com)$/;
    if(val.match(emailChk)){
      setEmailValidation(true);
    }else{
      setEmailValidation(false);
    }
    if(val.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/)){
      setCustomError(false);
    }else{
      setCustomError(true);
    }
}

  const onSubmit = data => {
    console.log(data);
    // fetch('http://localhost:8000/api/contact-us-save', {
    fetch('https://backend.aspiretss.com/api/contact-us-save', {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
       }
      })
        .then(response =>response)
        .then(result => {
          console.log(result);
          reset();
          if(result.status === 200){
            cogoToast.success(<p>Thanks for contact us</p>, {
              position: 'top-right'
            });
          }
        });
  }

  return (
    <>
      <div className="line-42 mb-2 mx-auto mt-0 mt-sm-4"></div>
      <h2 className="text-blue my-3 text-center">Get In Touch</h2>
      <div className="row m-0 pt-4">
        <div className="col-xl-7 col-lg-6 mb-5 px-0 pe-lg-3 pe-xl-0">
          <div className="map-wrapper">
            <figure>
              <img src={mapImage} alt="map" />
            </figure>
          </div>
        </div>
        <div className="col-lg-6 col-xl-5 px-0 pe-xl-4 ps-xl-5 contact-info">
          <Tabs defaultActiveKey="USA" transition={false} className="mb-4">
            {/* <Tab
              eventKey="All"
              title={<div onClick={() => setMapImage(allMap)}>All</div>}
            >
              <div className="row contacts">
                <ContactDetails contactData={contactData} />
              </div>
            </Tab> */}
            {/* <Tab
              eventKey="Asia"
              title={<div onClick={() => setMapImage(asiaMap)}>Asia</div>}
            >
              <div className="row contacts">
                <ContactDetails contactData={asiaContactInfo} />
              </div>
            </Tab>
            <Tab
              eventKey="Europe"
              title={<div onClick={() => setMapImage(europeMap)}>Europe</div>}
            >
              <div className="row contacts">
                <ContactDetails contactData={europeContactInfo} />
              </div>
            </Tab> */}
            <Tab>
              <div></div>
            </Tab>
            <Tab>
              <div></div>
            </Tab>
            <Tab
              eventKey="USA"
              title={<div onClick={() => setMapImage(usaMap)}>USA</div>}
            >
              <div className="row contacts">
                <ContactDetails contactData={usaContactInfo} />
              </div>
            </Tab>
          </Tabs>
          <div className="p-4 bg-white rounded-3 mt-3">
            <form className="info-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="input-group mb-4">
                <input
                  type="text"
                  className="form-control px-3 w-100"
                  name="name"
                  autoComplete="off"
                  id="exampleInputName"
                  placeholder="Enter your name"
                  {...register("name", {
                    required: "Full name is required",
                  })}
                  onChange={(e)=> nameValidity(e.target.value)}
                />
                <p className="text-danger mb-0"> {errors.name ? "*" : ""}{errors.name?.message}</p>
                <p className="text-danger mb-0" style={{display: nameValidation ? "block":"none"}}>*Must be valid your name</p>
               
              </div>
              <div className="input-group mb-4">
                <input
                  type="email"
                  className="form-control px-3 w-100"
                  name="email"
                  id="exampleInputEmail1"
                  placeholder="Email"
                  autoComplete="off"
                  {...register("email", {
                    required: "Email is required",
                    // pattern: {
                    //   value: /^\S+@\S+$/i,
                    //   message: "This is not a valid email",
                    // },
                  })}
                  onChange={(e)=>handleChange(e.target.value)}
                />
                {customeError?<p style={{color: "red"}}>Email is invalid yet, please check</p>:''}
                <p className="text-danger mb-0"> {errors.email ? "*" : ""}{errors.email?.message}</p>
                <p className="text-danger mb-0" style={{ display: !emailValidation ? 'none' : 'block' }}>*Must be valid your company email</p>
              </div>

              <div className="input-group mb-4">
                <textarea
                  className="form-control px-3 w-100"
                  name="message"
                  rows="7"
                  placeholder="Your Message"
                  {...register("message", {
                    required: "Message is required",
                  })}
                ></textarea>
                <p className="text-danger mb-0"> {errors.message ? "*" : ""}{errors.message?.message}</p>
              </div>

{/*              <input type="hidden" {...register("section")} value="sectionVal" />
              <input type="hidden" {...register("topic_name")} value="TopicVal" />*/}
              <div className="text-end mt-3 mb-3">
                { !error && !nameValidation && !emailValidation ? <button type="submit" className="btn btn-orange px-5"> Send </button>
                    : <button className="btn btn-orange px-5"> Send </button>   
                }
                

              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetInTouch;
