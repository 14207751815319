import React from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import { AiOutlineCloseSquare } from "react-icons/ai"
import useWindowDimensions from "../WindowSize";

const WatchVideoModal = ({ show, handleClose, link }) => {

  const {width} = useWindowDimensions();
  const videoHeight = width < 576 ? '50vh' : 'calc(100vh - 100px)';

  return (
    <Modal size="xl" centered show={show} onHide={handleClose} className="watchVideo">
      <Modal.Body className="p-0 bg-dark">
        <ReactPlayer
          url={link}
          playing={true}
          controls={true}
          muted={false}
          stopOnUnmount={false}
          width="100%"
          height={videoHeight}
        />
      </Modal.Body>
      <AiOutlineCloseSquare onClick={handleClose} />
    </Modal>
  );
};

export default WatchVideoModal;
