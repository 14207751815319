import React from 'react';
import { Link } from 'react-router-dom';
import NotFoundImg from '../../assets/images/404-pic2.webp';
import { Helmet } from 'react-helmet';

const NotFound = () => {
  return (
    <div className="not-found py-4 py-lg-5 d-flex align-items-center justify-content-center bg-white">
      <Helmet>
        <title>Not found | Aspire tech</title>
        <meta name="description" content="404 Not found page" />
        <meta name="keywords" content="" />
      </Helmet>
      <div className="container px-3">
        <div className="row flex-column-reverse flex-lg-row align-items-center justify-content-center">
          <div className="col-lg-5 banner-content text-center text-lg-start">
            <h1 className="banner-title text-blue">
              <span>O</span>OPS<span>!</span>
            </h1>
            <h2 className="text-blue">Page Not Found</h2>
            <p className="text-blue">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut
              temporibus laudantium minus. Aliquid iusto voluptate illum.
              Recusandae porro quasi ex amet consectetur!
            </p>
            <Link to="/">
              <button className="explore-btn btn-orange font-18">
                Go Home
              </button>
            </Link>
          </div>
          <div className="col-lg-7 banner-image">
            <figure className="text-center">
              <img src={NotFoundImg} alt="banner-img" className="img-fluid" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
