import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { MdArrowRight } from 'react-icons/md';
import { SiDatabricks } from 'react-icons/si';
import { solutions } from '../../data/mainNavData';

const SolutionNavItem = ({ setIsNavBlue, expanded , show , statefunc, clear }) => {
  const {
    cyberSecurity,
    cloudSecurity,
    dataCenter,
    others,
    industries,
    software,
  } = solutions;
  
  const [activeDropDown, setActiveDropDown] = useState('dropdownItem1');  
  return (
    <>
      <Dropdown 
        show={show}
        className="solutions me-3">
        <span 
        onClick={()=>{  
          clear();
          statefunc(!show); 
          }
        }
        >
          <Dropdown.Toggle variant="transparent" className="dropdown-title">
            Solutions
          </Dropdown.Toggle>
        </span>
        <div>
          <Dropdown.Menu className="p-0 main-dropdown">
            <Dropdown    
              show={activeDropDown === 'dropdownItem1' ? true : false}             
              onClick={() => {
                console.log("test")
                  setActiveDropDown('dropdownItem1');        
              }}
            >
              <Dropdown.Toggle
                variant="transparent"
                className={`${
                  activeDropDown === 'dropdownItem1' && 'bg-orange'
                } toggle-btn active py-3 w-100 text-white d-flex align-items-center justify-content-between`}
              >
                <div className="flex-grow-1 text-center">Cyber Security</div>{' '}
                <MdArrowRight />
              </Dropdown.Toggle>
              <Dropdown.Menu
              className="p-0 innerDropDown dropdownItem1">
                <h4 className="text-white text-center pt-2">
                  <SiDatabricks className="mb-1 text-white" /> Cyber Security
                </h4>
                <ul className="options px-3 px-md-4 pt-0 pb-3 font-16 d-flex flex-column gap-md-4 flex-md-row justify-content-around">
                  <div>
                    {cyberSecurity.slice(0, 11).map((item, index) => (
                      <li
                      onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }}  
                        key={index}>
                        <Link to={`${item.link}`}
                        className='menuItem'
                        onClick={() => {
                          expanded(expanded ? false : 'expanded');
                        }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                  <div className="divideLine"></div>
                  <div>
                    {cyberSecurity.slice(11, 22).map((item, index) => (
                      <li 
                      onClick={ ()=>{   
                        clear();
                        statefunc(!show);                    
                     }}                       
                      key={index}>
                        <Link to={`${item.link}`}
                        className='menuItem'
                        onClick={() => {
                          expanded(expanded ? false : 'expanded');
                        }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                  <div className="divideLine"></div>
                  <div>
                    {cyberSecurity.slice(22).map((item, index) => (
                      <li
                      onClick={ ()=>{   
                        clear();
                        statefunc(!show);                    
                     }}
                       key={index}>
                        <Link to={`${item.link}`}
                        className='menuItem'
                        onClick={() => {
                          expanded(expanded ? false : 'expanded');
                        }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              show={activeDropDown === 'dropdownItem2' ? true : false}
              onClick={() => {
                setActiveDropDown('dropdownItem2');
              }}
            >
              <Dropdown.Toggle
                variant="transparent"
                className={`${
                  activeDropDown === 'dropdownItem2' && 'bg-orange'
                } toggle-btn active py-3 w-100 text-white d-flex align-items-center justify-content-between`}
              >
                <div className="flex-grow-1 text-center">Cloud Security</div>{' '}
                <MdArrowRight />
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0 innerDropDown dropdownItem2">
                <h4 className="text-white text-center pt-2">
                  <SiDatabricks className="mb-1 text-white" /> Cloud Security
                </h4>

                <ul className="options px-3 px-md-4 pt-2 pt-lg-4 pb-3 font-16 d-flex flex-column gap-md-4 flex-md-row justify-content-around">
                  <div>
                    {cloudSecurity.slice(0, 8).map((item, index) => (
                      <li 
                      onClick={ ()=>{   
                        clear();
                        statefunc(!show);                    
                     }}
                      key={index}>
                        <Link to={`${item.link}`}
                        className='menuItem'
                        onClick={() => {
                          expanded(expanded ? false : 'expanded');
                        }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                  <div className="divideLine"></div>
                  <div>
                    {cloudSecurity.slice(8, 16).map((item, index) => (
                      <li
                      onClick={ ()=>{   
                        clear();
                        statefunc(!show);                    
                     }}
                       key={index}>
                        <Link to={`${item.link}`}
                        className='menuItem'
                        onClick={() => {
                          expanded(expanded ? false : 'expanded');
                        }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              show={activeDropDown === 'dropdownItem3' ? true : false}
              onClick={() => {
                setActiveDropDown('dropdownItem3');
              }}
            >
              <Dropdown.Toggle
                variant="transparent"
                className={`${
                  activeDropDown === 'dropdownItem3' && 'bg-orange'
                } toggle-btn active py-3 w-100 text-white d-flex align-items-center justify-content-between`}
              >
                <div className="flex-grow-1 text-center">Data Center</div>{' '}
                <MdArrowRight />
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0 innerDropDown dropdownItem3">
                <h4 className="text-white text-center pt-2">
                  <SiDatabricks className="mb-1 text-white" /> Data Center
                </h4>
                <ul className="options px-3 px-md-4 pt-2 pt-lg-4 pb-3 font-16 d-flex flex-column gap-md-4 flex-md-row justify-content-around">
                  <div>
                    {dataCenter.slice(0, 6).map((item, index) => (
                      <li onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }} key={index}>
                        <Link to={`${item.link}`}
                        className='menuItem'
                        onClick={() => {
                          expanded(expanded ? false : 'expanded');
                        }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                  <div className="divideLine"></div>
                  <div>
                    {dataCenter.slice(6).map((item, index) => (
                      <li onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }} key={index}>
                        <Link to={`${item.link}`}
                        className='menuItem'
                        onClick={() => {
                          expanded(expanded ? false : 'expanded');
                        }}
                        >{item.navItem}</Link>
                        <a href={item.link}>{item.navItem}</a>
                      </li>
                    ))}
                  </div>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              show={activeDropDown === 'dropdownItem4' ? true : false}
              onClick={() => {
                setActiveDropDown('dropdownItem4');
              }}
            >
              <Dropdown.Toggle
                variant="transparent"
                className={`${
                  activeDropDown === 'dropdownItem4' && 'bg-orange'
                } toggle-btn active py-3 w-100 text-white d-flex align-items-center justify-content-between`}
              >
                <div className="flex-grow-1 text-center">Others Solutions </div>{' '}
                <MdArrowRight />
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0 innerDropDown dropdownItem4">
                <h4 className="text-white text-center pt-2">
                  <SiDatabricks className="mb-1 text-white" /> Others Solutions
                </h4>
                <ul className="options px-3 px-md-4 pt-0 pb-3 font-16 d-flex flex-column gap-md-4 flex-md-row justify-content-around">
                  <div>
                    {others.slice(0, 10).map((item, index) => (
                      <li onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }} key={index}>
                        <Link to={`${item.link}`}
                        className='menuItem'
                        onClick={() => {
                          expanded(expanded ? false : 'expanded');
                        }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                  <div className="divideLine"></div>
                  <div>
                    {others.slice(10).map((item, index) => (
                      <li onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }} key={index}>
                        <Link to={`${item.link}`}
                        className='menuItem'
                        onClick={() => {
                          expanded(expanded ? false : 'expanded');
                        }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              show={activeDropDown === 'dropdownItem5' ? true : false}
              onClick={() => {
                setActiveDropDown('dropdownItem5');
              }}
            >
              <Dropdown.Toggle
                variant="transparent"
                className={`${
                  activeDropDown === 'dropdownItem5' && 'bg-orange'
                } toggle-btn active py-3 w-100 text-white d-flex align-items-center justify-content-between`}
              >
                <div className="flex-grow-1 text-center">By Industries</div>{' '}
                <MdArrowRight />
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0 innerDropDown dropdownItem5">
                <h4 className="text-white text-center pt-2">
                  <SiDatabricks className="mb-1 text-white" /> By Industries
                </h4>
                <ul className="options px-3 px-md-4 pt-2 pt-lg-4 pb-3 font-16 d-flex flex-column gap-md-4 flex-md-row justify-content-around">
                  <div>
                    {industries.slice(0, 4).map((item, index) => (
                      <li onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }} key={index}>
                        <Link to={`${item.link}`}
                        className='menuItem'
                        onClick={() => {
                          expanded(expanded ? false : 'expanded');
                        }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                  <div className="divideLine"></div>
                  <div>
                    {industries.slice(4).map((item, index) => (
                      <li onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }} key={index}>
                        <Link to={`${item.link}`}
                        className='menuItem'
                        onClick={() => {
                          expanded(expanded ? false : 'expanded');
                        }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              show={activeDropDown === 'dropdownItem6' ? true : false}
              onClick={() => {
                setActiveDropDown('dropdownItem6');
              }}
            >
              <Dropdown.Toggle
                variant="transparent"
                className={`${
                  activeDropDown === 'dropdownItem6' && 'bg-orange'
                } toggle-btn py-3 w-100 text-white d-flex align-items-center justify-content-between`}
              >
                <div className="flex-grow-1 text-center">By Software</div>{' '}
                <MdArrowRight />
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-0 innerDropDown dropdownItem6">
                <h4 className="text-white text-center pt-2">
                  <SiDatabricks className="mb-1 text-white" /> By Software
                </h4>
                <ul className="options px-3 px-md-4 pt-2 pt-lg-4 pb-3 font-16 d-flex flex-column gap-md-4 flex-md-row justify-content-around">
                  <div>
                    {software.slice(0, 4).map((item, index) => (
                      <li onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }} key={index}>
                        <Link to={`${item.link}`}
                        className='menuItem'
                        onClick={() => {
                          expanded(expanded ? false : 'expanded');
                        }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                  <div className="divideLine"></div>
                  <div>
                    {software.slice(4).map((item, index) => (
                      <li onClick={ ()=>{   
                            clear();
                            statefunc(!show);                    
                         }} key={index}>
                        <Link to={`${item.link}`}
                        className='menuItem'
                        onClick={() => {
                          expanded(expanded ? false : 'expanded');
                        }}
                        >{item.navItem}</Link>
                      </li>
                    ))}
                  </div>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </div>
      </Dropdown>
    </>
  );
};

export default SolutionNavItem;
